body {
  min-height: 100vh !important;
  background-image: url(background-bom.svg);
  background-size: cover;
  background-color: #1a1537;
  background-repeat: no-repeat;
}

.logo {
  width: 125px;
}

.main-content {
  padding-top: 80px;
  min-height: 60vh;
}

.message-box {
  padding: 5px 24px;
  border-top: 1px solid #867bcd;
  border-bottom: 1px solid #867bcd;
  margin-top: 20px;
  background-color: rgba(26, 21, 53, 0.4);
}

body input[type="text"],
body input[type="password"],
body input[type="email"] {
  background-color: initial !important;
}

.custom-link {
  text-decoration: underline !important;
  cursor: pointer !important;
}

div.alert.alert-danger ul {
  margin-bottom: 0px !important;
}

div.activate-message.alert {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
  margin-bottom: 0.5rem !important;
}

